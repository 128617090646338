/**
 * 团长地址类型相关接口
 */
import request from '@/utils/request'

export const getSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/host_commissions/setup'
  })
}

export const getHostCommissions = (params) => {
  return request({
    method: 'GET',
    url: '/cms/host_commissions',
    params
  })
}

export const getHostCommissionsId = (id) => {
  return request({
    method: 'GET',
    url: `/cms/host_commissions/${id}`
  })
}

export const getCommissionTypeList = () => {
  return request({
    method: 'GET',
    url: '/cms/host_commissions/commission_type_list'
  })
}

export const postHostCommissions = (data) => {
  return request({
    method: 'POST',
    url: '/cms/host_commissions',
    data
  })
}

export const getExport = (params) => {
  return request({
    method: 'get',
    url: '/cms/host_commissions/export',
    params
  })
}
