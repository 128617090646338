import * as hostCommissionsApi from '@/services/host-commissions'
import loading from '@/utils/loading.json'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'

export default {
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem,
      tabActivityIndex: 0
    }
  },
  mounted () {
    const _this = this
    _this.queryData(hostCommissionsApi.getSetup)
  },
  activated () {
    if (this.isActive) this.queryData(hostCommissionsApi.getSetup)
    this.isActive = true
  },
  methods: {
    setGlobalLoading (show) {
      const _this = this
      show ? _this.$store.state.app.loading = this.$loading(loading) : this.$store.state.app.loading.close()
    },
    handleMainTableAddClick () {
      const _this = this
      _this.$router.push({
        name: 'buddyManagerCommissionAdd'
      })
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      hostCommissionsApi.getHostCommissions(this.searchData).then(res => {
        if (res.data) {
          this.tableData = []
          this.$nextTick(() => {
            this.tableData = res.data.objects
            this.tabData = res.data.scopes
            Object.assign(this.pageData, res.data.pagination)
          })
        }
      }).finally(() => {
        this.loading = false
      })
    },
    // 页面跳转
    forwardToPage (data, name) {
      if (data.model_name === 'delivery_order') {
        this.$router.push({
          name: 'ordersManagerDeliveryOrdersList',
          params: {
            id: data.id.value
          }
        })
      }
    },
    // 点击操作按钮
    handleClick (type, data) {
      const _this = this
      if (type?.action === 'view') {
        _this.$router.push({
          name: 'buddyManagerCommissionDetail',
          params: {
            id: data.id.value
          }
        })
      }
    },
    // 导出数据接口
    handleExport () {
      this.$refs.searchForm.commonOperate(hostCommissionsApi.getExport, (data) => {
        this.$router.push({
          name: 'downloadCenter',
          params: { data }
        })
      }, this)
    }
  }
}
